<template>
  <div
    id="editBuildingUnit"
    class="modal fade"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('floor.edit_building_unit') }} {{ currentBuildingUnit.unit_number }}
          </h5>
        </div>
        <div v-show="isRegistration">
          <div class="modal-body">
            <InputNumber
              v-model="params.unit_number"
              input-id="unit_number"
              :label-text="$t('floor.unit_number')"
              :value-input="params.unit_number"
            />
            <InputNumber
              v-model="params.area"
              input-id="area"
              :label-text="$t('floor.building_unit_area')"
              :value-input="params.area"
            />
            <InputSelect2
              v-model="params.holder_id"
              input-id="holder_id"
              :label-text="$t('floor.building_unit_holder')"
              :options="collectionUsers"
              :selected="params.holder_id"
              :place-holder="$t('floor.select_holder')"
              type-select="single_select"
            />
            <div
              v-if="isHired"
              class="form-group row"
            >
              <label class="col-form-label col-2">{{ $t('floor.building_unit_status') }}</label>
              <div class="col-10 d-flex align-items-center">
                {{ $t('floor.hired') }}
              </div>
            </div>
            <InputRadioButtons
              v-else
              v-model="params.status"
              input-id="status"
              :label-text="$t('floor.building_unit_status')"
              :options="status"
              :value-input="params.status"
            />
            <div class="form-group row">
              <label
                :for="uniqueId('document_')"
                :class="['col-form-label', 'col-lg-2']"
              >{{ $t('building_unit.document') }}</label>
              <input
                :id="uniqueId('document_')"
                multiple
                :accept="'.jpg, .jpeg, .png, .doc, .docx, .xlsx, .xls, .pdf'"
                :placeholder="'.jpg, .jpeg, .png, .doc, .docx, .xlsx, .xls, .pdf'"
                :type="'file'"
                :class="'col-lg-10'"
                @change="handleChangeDocument"
              >
            </div>
            <div
              :style="{display: 'flex', flexDirection: 'column', gap: '8px'}"
              class="form-group row"
            >
              <div
                v-for="(picture) in currentBuildingUnit.pictures"
                :key="picture.uuid"
                class="col bms-building-note-attachments-container"
              >
                <div
                  v-if="picture['image?']"
                  class="bms-building-note-attachments-preview"
                >
                  <a
                    :href="picture.url"
                    target="_blank"
                    rel="noopener"
                  >
                    <img :src="picture.url">
                  </a>
                </div>
                <div v-else-if="picture['video?']">
                  <clickable-link
                    :url="picture.url"
                    :name="picture.file_identifier"
                  >
                    <i class="far fa-file-video" />
                  </clickable-link>
                </div>
                <div v-else-if="picture['pdf?']">
                  <clickable-link
                    :url="picture.url"
                    :name="picture.file_identifier"
                  >
                    <i class="far fa-file-pdf" />
                  </clickable-link>
                </div>
                <div v-else>
                  <clickable-link
                    :url="picture.url"
                    :name="picture.file_identifier"
                  >
                    <i class="far fa-file" />
                  </clickable-link>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                :class="['col-form-label', 'col-lg-2']"
              >
                {{ $t('building_unit.sub_merchant.title') }}
              </label>
              <div class="col-lg-10">
                <a
                  class="btn btn-outline-secondary"
                  :href="subMerchantPath()"
                >
                  {{ $t('sub_merchant.edit') }}
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t('floor.close') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="submitConfirm"
            >
              {{ $t('floor.submit') }}
            </button>
          </div>
        </div>

        <div v-show="isConfirm">
          <div class="modal-body">
            <FieldConfirm
              :label-text="$t('floor.unit_number')"
              :value="params.unit_number"
            />
            <FieldConfirm
              :label-text="$t('floor.building_unit_area')"
              :value="params.area"
            />
            <FieldConfirm
              :label-text="$t('floor.building_unit_holder')"
              :value="collectionUsers[params.holder_id] || ''"
            />
            <FieldConfirm
              :label-text="$t('floor.building_unit_status')"
              :value="params.status ? $t(`floor.${params.status}`) : ''"
            />
            <FieldConfirm
              :label-text="$t('building_unit.document')"
              :value="formattedSelectedFiles"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="backToRegistration"
            >
              {{ $t('floor.back') }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="submitForm"
            >
              {{ $t('floor.save_change') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { uniqueId } from "lodash";
import prettyBytes from "pretty-bytes";
import InputNumber from "../components/inputNumber.vue";
import InputRadioButtons from "../components/inputRadioButtons";
import InputSelect2 from "../components/inputSelect2.vue";
import FieldConfirm from "../components/fieldConfirm.vue";
import ClickableLink from './clickableLink.vue';

import show_flash_mixins from "../mixins/show_flash";

export default {
  components: {
    InputNumber,
    InputRadioButtons,
    InputSelect2,
    FieldConfirm,
    ClickableLink
  },
  mixins: [show_flash_mixins],
  data: function () {
    return {
      isRegistration: true,
      isConfirm: false,
      params: {},
      selectedFiles: []
    };
  },
  computed: {
    ...mapState({
      currentBuildingUnit: (state) => state.floor.currentBuildingUnit,
      buildingUnitStatus: (state) => state.floor.buildingUnitStatus,
      collectionUsers: (state) => state.floor.users,
      isValid: (state) => state.floor.isValid
    }),
    status: function () {
      const self = this;

      return this.buildingUnitStatus.reduce(function (status, el) {
        status[el] = self.$t(`floor.${el}`);
        return status;
      }, {});
    },
    isHired() {
      return this.currentBuildingUnit.status === "hired";
    },
    formattedSelectedFiles() {
      if (this.selectedFiles.length === 0) return;

      return this.selectedFiles
        .map(
          (file) => `${file.name} - ${file.type} - ${prettyBytes(file.size)}`
        )
        .join(", ");
    }
  },
  watch: {
    currentBuildingUnit: function (val) {
      this.params = {
        id: val.id,
        unit_number: val.unit_number,
        area: val.area,
        holder_id: val.holder_id,
        status: val.status
      };
    }
  },
  methods: {
    uniqueId,
    prettyBytes,
    submitForm: async function () {
      const self = this;
      const formData = new FormData();
      Object.keys(self.params).forEach((key) =>
        formData.append(`building_unit[${key}]`, self.params[key])
      );
      self.selectedFiles.forEach((file, _index) =>
        formData.append(`building_unit[pictures][][file]`, file)
      );
      await this.$store.dispatch("floor/editBuildingUnit", {
        params: formData,
        id: self.params.id
      });
      this.isRegistration = true;
      this.isConfirm = false;

      this.show_flash(this.isValid);
    },
    submitConfirm: function () {
      this.isRegistration = false;
      this.isConfirm = true;
    },
    backToRegistration: function () {
      this.isConfirm = false;
      this.isRegistration = true;
    },
    handleChangeDocument: function (event) {
      const self = this;
      const fileList = event.currentTarget.files || event.dataTransfer.files;
      for (const file of fileList) {
        self.selectedFiles.push(file);
      }
    },
    removeMsg: function (event, index) {
      return;
    },
    subMerchantPath: function() {
      return this.currentBuildingUnit.sub_merchant ? `/sub_merchants/${this.currentBuildingUnit.sub_merchant.id}/edit` :
      `/sub_merchants/new?subscribable_type=BuildingUnit&subscribable_id=${this.currentBuildingUnit.id}`;
    }
  }
};
</script>
