<template>
  <a
    :href="url"
    target="_blank"
    rel="noopener"
  >
    <slot />
    <span>{{ name }}</span>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ""
    }
  }
};
</script>
